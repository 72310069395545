import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Icon, makeStyles } from "@material-ui/core";

import useCart from "../store/hooks/useCart";
import { isArray, isEmpty, isNil, toNumber } from "lodash";
import { EMPLOYEE_STORE_SHOPPING_CART_ROUTE } from "../routes/EmployeeStoreRouter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BUTTONS_HEIGHT } from "./ProductActions";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { TOAST_CONTAINER_LAYOUT } from "../../../util/Constants";

const useStyles = makeStyles((theme) => ({
  addCartItemBtn: {
    height: BUTTONS_HEIGHT,
    color: theme.palette.content.mainColor,
    fontWeight: 700,
    border: `1px solid ${theme.palette.content.mainColor}`,
    "&:hover": {
      border: `1px solid ${theme.palette.content.mainColor}`,
      color: theme.palette.content.mainColor,
    },
    [theme.breakpoints.down("md")]: {
      minWidth: 160,
    },
  },
  removeCartItemBtn: {
    height: BUTTONS_HEIGHT,
    color: theme.palette.semaphore.red,
    fontWeight: 700,
    border: `1px solid ${theme.palette.semaphore.red}`,
    "&:hover": {
      color: theme.palette.semaphore.red,
      border: `1px solid ${theme.palette.semaphore.red}`,
    },
    [theme.breakpoints.down("md")]: {
      minWidth: 160,
    },
  },
  itemAlreadyInCart: {
    height: BUTTONS_HEIGHT,
    fontWeight: 700,
    border: `1px solid`,
    "&:hover": {},
    [theme.breakpoints.down("md")]: {
      minWidth: 160,
    },
  },
}));

export default function AddToCartButton({
  item,
  disableRemove,
  externalQuantity,
  disabled = false,
}) {
  const classes = useStyles();
  let history = useHistory();
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(item?.minQuantity);

  const { cartItems, handleAddCartItem, removeCartItem } = useCart();

  useEffect(() => {
    if (externalQuantity !== null) {
      setQuantity(externalQuantity);
    }
  }, [externalQuantity]);

  const isProductInCart = useMemo(() => {
    return (
      !isNil(cartItems) &&
      !isEmpty(cartItems) &&
      isArray(cartItems) &&
      cartItems.some((x) => x.sku === item?.sku)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems]);

  const handleAddCartItemClick = useCallback(() => {
    const productHasEnoughStock =
      toNumber(item?.stock) > 0 &&
      toNumber(item?.stock) >= toNumber(item?.minQuantity);

    if (!productHasEnoughStock) {
      toast.warn(
        "Producto no cuenta con unidades suficientes para alcanzar ume",
        {
          containerId: TOAST_CONTAINER_LAYOUT,
          toastId: "handleAddCartItemClick",
        }
      );
      return;
    }
    handleAddCartItem({
      product: item,
      quantity: quantity || item?.minQuantity,
    });
  }, [handleAddCartItem, item, quantity]);

  return (
    <>
      {isProductInCart && disableRemove === true ? (
        <Button
          fullWidth={true}
          className={classes.itemAlreadyInCart}
          size="small"
          onClick={() => history.push(EMPLOYEE_STORE_SHOPPING_CART_ROUTE)}
          startIcon={<Icon className="fas fa-shopping-cart" />}
        >
          {t("EMPLOYEE_STORE.IN_CART")}
        </Button>
      ) : isProductInCart &&
        (isNil(disableRemove) || disableRemove === false) ? (
        <Button
          variant="outlined"
          fullWidth={true}
          className={classes.removeCartItemBtn}
          size="small"
          onClick={() => removeCartItem(item?.sku)}
          startIcon={<Icon className="fas fa-trash-alt" />}
          disabled={disabled}
        >
          {t("EMPLOYEE_STORE.RMV_FROM_CART")}
        </Button>
      ) : (
        <Button
          variant="outlined"
          fullWidth={true}
          className={classes.addCartItemBtn}
          size="small"
          onClick={() => handleAddCartItemClick()}
          startIcon={<Icon className="fas fa-cart-plus" />}
          disabled={disabled}
        >
          {t("EMPLOYEE_STORE.ADD_TO_CART")}
        </Button>
      )}
    </>
  );
}
